function Footer() {
    return (
        <footer id="Footer">
            <div className="footer">
                <p> Designed and developed by Saugat Khatri.
                    2021&copy;<br />
                    <i className="fa fa-chevron-down"></i></p>
            </div>
        </footer>
    )
}

export default Footer
