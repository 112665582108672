// import 'bootstrap/dist/css/bootstrap.min.css'
import './css/style.css'
import './App.css';
// import Container from 'react-bootstrap/Container'
import Home from './pages/Home'


function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
