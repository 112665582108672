import colorApp from "../img/colorApp.png";
import codical from "../img/codical.png";
import notebahadur from "../img/notebahadur.png";
import snakes from "../img/snakes.png";
// import sis from "../img/SIS.png";
import elegantShop from "../img/react-shop.png";

const projectsData = [
  {
    title: "Elegant Shop",
    image: elegantShop,
    description:
      "A e-commerce website built with React and Redux with firebase authentication and stripe payment gateway.",
    previewLink: "https://elegant-shop.saugatkhatri.com.np/",
    gitLink: "https://github.com/khatrisaugat/ReactShop",
  },
  {
    title: "Codical Tech",
    image: codical,
    description: "A website design that is mordern and looks good.",
    previewLink: "https://codicaltech.com/",
    gitLink: "https://github.com/khatrisaugat/CodicalTech",
  },
  {
    title: "Notebahadur",
    image: notebahadur,
    description:
      "A website created to post content that helps students in their education",
    previewLink: "https://notebahadur.com/",
    gitLink: "",
  },
  {
    title: "Snakes Game",
    image: snakes,
    description: "A simple snakes game",
    previewLink: "http://snakes.saugatkhatri.com.np/",
    gitLink: "https://github.com/khatrisaugat/SnakesGame",
  },

  {
    title: "Color App",
    image: colorApp,
    description:
      "A simple react application that can be used to copy color codes",
    previewLink: "http://colorapp.saugatkhatri.com.np/",
    gitLink: "https://github.com/khatrisaugat/Color-app",
  },
];
export default projectsData;
